import fetchAll from "./fetchAll";
import fetchByVoucher from "./fetchByVoucher";
import fetchBydate from "./fetchBydate";
import cancel from "./cancel";
import fetchByFile from "./fetchByFile";
import fetchNCompra from "./fetchNCompra";

export default {
    fetchAll,
    fetchByVoucher,
    fetchBydate,
    cancel,
    fetchByFile,
    fetchNCompra
}