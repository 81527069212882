import axios from "axios";

const fetchByFile = async function (numero, empresa) {
    try {
        const response = await axios.get(`/api/nrodcto_compra/${numero}/${empresa}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchByFile;