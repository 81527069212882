import axios from "axios";

const fetchAll = async function (empresa, fecha_inicio) {
    try {
        const response = await axios.get(`/api/libro_compra/${empresa}/${fecha_inicio}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchAll;