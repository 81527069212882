import axios from "axios";

const fetchByVoucher = async function (voucher, empresa) {
    try {
        const response = await axios.get(`/api/voucher_compra/${empresa}/${voucher}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default fetchByVoucher;